@import "../../Variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

.TheCFCapital {
    body {
        line-height: 1.5;
        font-weight: 300;
        color: #5e5e5e;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        h1 {
            font-size: 2.5rem !important;
            font-weight: 300;
            color: $thm_black;
        }

        h2 {
            font-size: 1.25rem !important;
            font-weight: 300;
            color: $thm_base_color;
        }

        h3 {
            font-size: 2.375rem !important;
            font-weight: 300;
            color: $thm_base_color;
        }

        h4 {
            font-size: 1.75rem !important;
            font-weight: 300;
            color: $thm_base_color;
        }

        .col-md-2 {
            flex: 0 0 20%;
            max-width: 20%;
        }
        a {
            color: $thm_base_color;
        }
        .fullWidth {
            width: 100%;
            max-width: 100%;
        }

        &:not(.home-page) {
            footer {
                a,
                h3 {
                    color: #fff;
                }
            }
        }
    }
    @mixin button {
        width: max-content;
        border: 1px $thm_base_color solid;
        margin: 30px 0 0;
        padding: 8px 10px;
        border-radius: 8px;
        color: $thm_base_color;
        
        &:hover {
            border-color: $thm_base_color;
            background-color: $thm_base_color;
            color: #fff;
            text-decoration: none;
            a {
                color: #fff;
            }
        }
    }

    @mixin lightButton {
        @include button();
        border-color: #fff;
        color: #fff;
        a {
            color: #fff;
        }
        &:hover {
            border-color: #fff;
            background-color: #fff;
            color: $thm_base_color;
            a {
                color: $thm_base_color;
            }
        }
    }
    header.transparent {
    }
    .site-footer,
    .header {
        background: #0F2E4A;
    }
    .home-page {
        .banner {
            max-width: 100%;
            background-color: #0f1126;
            background-image: url('/assets/img/MGMTCapitals/bg-homepage-1.jpg');
            padding: 230px 0 230px;
            background-attachment: fixed;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            
            .item {
                height: max-content;
                right: unset;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;

                margin: auto;
                text-align: center;
                
                h1 {
                    color: #fff;
                }

                h2 {
                    color: #fff;
                    margin: 30px 0;
                }

                a {
                    @include lightButton();
                }
            }

            img {
                width: 60%;
                display: block;
                margin: 100px auto;
            }
        }

        .section-1 {
            padding: 50px 20px;
            
            h3 {
                color: $thm_black;
                text-align: center;
                margin-bottom: 50px;
            }
            .row {
                align-items: top;
                text-align: center;
                justify-content: space-between;
                >div {
                    min-height: 300px;
                    padding-top: 10px;
                }
            }
        }

        .section-2 {
            background-color: #f6f6f6;
            padding: 50px 20px;
            .row {
                align-items: center;
                #chart {
                    background: white;
                    box-shadow: 1px 1px 3px 0 #bbbbbb;
                }
                a {
                    @include button();
                }
            }
        }

        .section-4 {
            font-size: 14px;
            padding: 10px 0 5px;

            .payment-gallery {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                svg {
                    width: 54px;
                    height: 48px;
                    margin: 0 10px;
                }

                .logo-bitcoin svg {
                    width: 80px
                }
            }
        }

        .section-3 {
            background-color: #f6f6f6;
            padding: 100px 0;
            
            .row {
                > div :first-child {
                    padding-right: 40px;
                }
            }
            .item-cnbs-link {
                a {
                    min-height: 30px;
                    display: block;
                    margin-bottom: .75erm;
                }
            }

            .btn {
                @include button();
                margin-right: 20px;
            }
        }

        .footer-top {
            display: none;
        }

        footer {
            color: #5e5e5e;

            h3 {
                font-size: 1.7rem !important;
                font-weight: 500;
                color: $thm_black;
            }

            a {
                color: $thm_black;
            }
        }
    }

    .error-page {
        h2,
        h3 {
            color: #000;
        }
    }

    .account-levels-page {
        .page-header-levels {
            max-width: 100%;
            padding: 100px 0 250px;
            background: #000000 url('/assets/img/levels-background.jpg') no-repeat scroll top center / cover;
            color: #fff;

            a {
                padding: 10px 20px;
                background: #fff;
                color: #000;
            }
        }

        .row {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: flex-start;

            > div {
                border: 1px #f0f0f0 solid;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin: 10px;

                .amount {
                    font-size: 24px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        display: flex;

                        &:before {
                            content: '✔';
                            margin-right: 10px;
                            color: $color-buy;
                            font-size: 14px;
                        }
                    }
                }

                .button {
                    width:100%;
                    font-size: 16px;
                    text-align: center;
                    background-color: $color-sell;
                    padding: 10px;
                    display: block;
                    color: #fff;
                }
            }
        }
    }

    .page-header-stocks {
        max-width: 100%;
        margin: 0;
        padding: 100px 0 250px;
        background: #000000 url('/assets/img/NordexInvest/homepage-banner.jpg') no-repeat scroll top center / cover;
        color: #fff;

        a {
            padding: 10px 20px;
            background: #fff;
            color: #000;
        }
    }

    .page-header-trading-view {
        max-width: 100%;
        margin: 0;
        padding: 60px 0 70px;
        background: url('/assets/img/NordexInvest/homepage-banner.jpg') no-repeat scroll top center / cover;
        color: #000;
        margin-bottom: 100px;
    }

    .webtrader-page {
        font-size: 14px;
        line-height: normal;

        .page-header-webtrader {
            margin-bottom: 100px;
        }
    }

    h2 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .mytop {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #e1e5e9;
        padding: 20px;

        h3 {
            color: #1cbb4b;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 20px;
        }

        ul {
            width: 100%;
            margin: auto 0;
            padding: 0 0 0 20px;
            font-size: 16px;
            list-style-type: none;

            li {
                margin-bottom: 10px;
                font-size: 16px;

                &:before {
                    color: #0fa937;
                    content: "✔";
                    font: 15px/1;
                    margin-left: -20px;
                    margin-right: 5px;
                }
            }
        }
    }

    hr {
        background: #151822 !important;
    }

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-right: 0;
        padding-left: 0;
    }

    .platform-page {
        .row {
            margin: 0!important;
        }
    }
}